<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card class="py-2 px-3">
        <div class="">
          <v-row class="me-1">
            <v-col cols="10">
              <p class="ms-0 my-0 f-sanspro fw-600 fs-10pt" v-cHex="hexShades(cor.active.B,0.5)">
                <i class="btn-icon-left" v-i:duo#chart-line#18></i>
                Evolução da <span class="fw-800">VISUALIZAÇÃO</span> das encomendas (2021/2)
              </p>
            </v-col>
            <v-col cols="2" class="">
              <v-btn color="" class="text-nonecase" text x-small @click="exportar">
                <i v-i:duo#download#20 class="btn-icon-left"></i>
                <span class="">exportar</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-card class="py-1 px-2 mt-3" v-bg:b#4>
            <div class="my-1 mx-0 flexb falign-center" style="justify-content: space-between;">
              <div class="">
                <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
                  <i class="btn-icon-left" v-i:duo#graduation-cap#16></i>
                  <span class="">Qtde de professores com encomendas</span>
                  <span class="ms-3 f-sanspro fs-12pt fw-800">{{qtdeProfs}}</span>
                </p>
                <p class="ms-0 my-0 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
                  <i class="btn-icon-left" v-i:duo#graduation-cap#16></i>
                  <span class="">Qtde profs que visualizaram encomenda</span>
                  <span class="ms-3 f-sanspro fs-12pt fw-800">{{qtdeProfsCientes}}</span>
                </p>
              </div>
              <div class="text-center">
                <p class="ms-0 mb-2 f-sanspro fw-600 fs-9pt" v-cHex="hexShades(cor.active.B,0.5)">
                  <span class="">Visualização</span>
                  <span class="ms-1 border px-1 rounded">{{parseFloat(qtdeProfsCientes / qtdeProfs *100).toFixed(2)}}%</span>
                </p>
                <div class="ms-0 my-0 mt-n1 f-roboto fw-500 fs-13pt" v-cHex="hexShades(cor.active.B,1)">
                  <v-progress-circular v-if="qtdeProfsCientes > 0" :rotate="360" :size="60" :width="8" :value="(qtdeProfsCientes / qtdeProfs)*100" color="teal">
                    {{ parseInt(qtdeProfsCientes / qtdeProfs * 100) }}%
                  </v-progress-circular>
                </div>
              </div>
            </div>

            <v-row class="p-0 mx-1 mt-1 mb-0">
              <v-col cols="2" class="m-0 p-0" v-for="idx in [0,1,2,3,4,5]" :key="idx">
                <span class="fs-8pt f-roboto fw-800">{{labelDias[idx]}}</span>
                <span class="ms-1 fs-7pt f-raleway fw-300" v-if="labelDiasSem[idx] != undefined">{{labelDiasSem[idx].toUpperCase()}}</span>
              </v-col>
            </v-row>
            <v-sheet color="transparent" class="mt-n3">
              <v-sparkline :value="ultimosDias" label-size="12" :color="cor.active.I" height="80" padding="14" auto-draw smooth>
                <template v-slot:label="item">
                  {{item.value}}
                </template>
              </v-sparkline>
            </v-sheet>

          </v-card>
        </div>
        <v-overlay absolute :value="loading">
          <div class="text-center">
            <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
          </div>
          <div class="text-center mb-4" style="margin-top: 20px;">
            <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
          </div>
        </v-overlay>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-text-field class="my-0 pb-0" placeholder="filtrar por nome" v-model="filtro" dense solo>
          <template v-slot:append>
            <i v-i:ic#search#26></i>
          </template>
        </v-text-field>
        <div class="p-0 m-0 mt-n4 mb-2 text-center">
          <v-pagination class="m-0 p-0" circle v-model="page" :total-visible="7" :length="parseInt(Object.keys(profsCientes).length/qtdePage)+1"></v-pagination>
        </div>
        <v-card class="mt-0 p-1 card-scroll" style="height: 400px; max-height: 400px">
          <div class="m-0 p-0" v-for="(prof,key,index) in profsCientes" :key="key" v-if="prof.nome != undefined && index >= (page-1)*qtdePage && index < (page)*qtdePage">
            <v-container class="p-1 m-0">
              <v-row no-gutters align="center">
                <v-col cols="1">
                  <avataruser :userID="prof.id" size="34" />
                </v-col>
                <v-col cols="9" class="ps-1 text-truncate">
                  <span class="f-roboto fs-9pt">{{prof.nome}}</span>
                </v-col>
                <v-col cols="2" class="text-truncate">
                  <span class="f-roboto fw-300 fs-8pt">{{$moment(prof.lastView).fromNow()}}</span>
                </v-col>
              </v-row>
            </v-container>
            <hr class="m-0">
            <!-- <ucitem v-if="index < 50" :uc="uc" :area="area" @ucClick="ucClick" @onSave="onSave"/> -->
          </div>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js';
import avataruser from "./avatar-user.vue";
import { exportarPlanilha } from "@/components/export-planilha.js";
import moment from "moment";

export default {
  name: "acompanhamento",
  components: { avataruser },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      encomendasA2: {},
      profsAcessos: {},
      qtdeProfs: 0,
      qtdeProfsCientes: 0,
      profs: {},
      profsCientes: {},
      ultimosDias: [ 0, 0, 0, 0, 0, 0, 0 ],
      labelDias: [],
      labelDiasSem: [],
      filtro: "",
      page: 1,
      qtdePage: 30,
    }
  },
  watch: {
    filtro() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;

    self.loading = true;
    rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').on('value',function(snapshot) {
      self.encomendasA2 = snapshot.val();
      console.log("self.encomendasA2 ["+Object.keys(self.encomendasA2).length+"]",self.encomendasA2);
      rdb.ref('/curriculo/avaliacao/2021S2/profsCientes').on('value',function(snapshot) {
        self.profsAcessos = snapshot.val();
        console.log("self.profsAcessos ["+Object.keys(self.profsAcessos).length+"]",self.profsAcessos);
        self.build();
        self.loading = false;
      });
    });
  },
  methods:{

    build() {
      var self = this;
      self.profs = {};
      self.profsCientes = {};
      self.qtdeProfs = 0;
      self.qtdeProfsCientes = 0;

      self.ultimosDias = [ 0, 0, 0, 0, 0, 0 ];
      for(var i=0; i<6;i++) {
        self.labelDias[i] = moment().subtract(5-i,"days").format("DD");
        self.labelDiasSem[i] = moment().subtract(5-i,"days").format("ddd");
      }

      for(var key in self.encomendasA2) {
        var profID = self.encomendasA2[key].profID;
        var profNome = self.encomendasA2[key].profNome;
        var profCPF = self.encomendasA2[key].profCPF;
        if(self.profs[profID] == undefined) {
          if(self.profsAcessos[profID] != undefined) {
            self.qtdeProfsCientes++;
            var profCiente = self.profsAcessos[profID];
            var backlogs = {};
            var lastView = "";
            if(profCiente.createdAt != null) {
              backlogs[generatePushID()()] = profCiente.createdAt;
              lastView = profCiente.createdAt;
            } else {
              for(var idx in profCiente.logs) {
                backlogs[profCiente.logs[idx]] = profCiente.logs[idx];
                lastView = profCiente.logs[idx];
                break;
              }
            }
            if(profNome.toUpperCase().indexOf(self.filtro.trim().toUpperCase()) != -1) {
              self.profsCientes[profID] = {
                id: profID,
                nome: profNome,
                profCPF: profCPF,
                lastView: lastView
              };
            }
            for(var idx in backlogs) {
              var time = moment(backlogs[idx]);
              var dias = 5-moment().diff(time,"days");
              if(dias > 0 && dias < 7) {
                self.ultimosDias[dias]++;
              }
            }
          }
          self.qtdeProfs++;
          self.profs[profID] = {
            id: profID,
            nome: profNome,
            qtdeEncomendas: 0,
            encomendas: [],
          }
        }
        self.profs[profID].qtdeEncomendas++;
        self.profs[profID].encomendas.push(self.encomendasA2[key]);
      }
      console.log("self.profs ["+Object.keys(self.profs).length+"]",self.profs);
      console.log("self.qtdeProfs",self.qtdeProfs);
      console.log("self.qtdeProfsCientes",self.qtdeProfsCientes);
      console.log("self.ultimosDias",self.ultimosDias);
      console.log("self.profsCientes ["+Object.keys(self.profsCientes).length+"]",self.profsCientes);
    },

    exportar() {
      var self = this;
      console.log("exportar");
      //console.log("self.profsCientes ["+Object.keys(self.profsCientes).length+"]",self.profsCientes);
      //console.log(exportarPlanilha);
      var vet = snapshotValToArray(self.profsCientes);
      exportarPlanilha("appIntegra-ProfsCientes",vet);
    }
  }
}
</script>

<style scoped>
</style>
